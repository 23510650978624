<template>
  <el-card style="min-height: 45rem">
    <div style="margin-bottom: 1rem">
      <el-row :gutter="20">
        <el-col :span="3">
          <el-input v-model="searchForm.keyword" placeholder="用户编号|昵称|手机号"></el-input>
        </el-col>
        <el-col :span="3">
          <el-input v-model="searchForm.coupon_title" placeholder="卡券名称"></el-input>
        </el-col>
        <el-col :span="3">
          <el-input v-model="searchForm.merchant_title" placeholder="商家名称"></el-input>
        </el-col>
        <el-col :span="3">
          <el-input v-model="searchForm.cashier_uid" placeholder="核销员编号"></el-input>
        </el-col>
        <el-col :span="8">
          <el-date-picker
              style="width: 100%"
              v-model="searchForm.time"
              type="daterange"
              range-separator="至"
              start-placeholder="核销时间范围开始日期"
              end-placeholder="核销时间范围结束日期">
          </el-date-picker>
        </el-col>
        <el-col :span="4">
          <el-button-group>
            <el-button @click="searchGo">搜索</el-button>
            <el-button @click="searchReset">重置</el-button>
          </el-button-group>
          <el-button @click="exportExcel">导出</el-button>
        </el-col>
      </el-row>
    </div>
    <el-table :data="list">
      <el-table-column prop="id" label="编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="uid" label="用户编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="u_nickname" label="用户昵称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="u_phone" label="手机号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="coupon_title" label="卡券名称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="merchant_title" label="所属商家" show-overflow-tooltip></el-table-column>
      <el-table-column prop="clerk_uid" label="核销员用户编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="clerk_nickname" label="核销员昵称" show-overflow-tooltip></el-table-column>
      <el-table-column label="核销时间" show-overflow-tooltip>
        <template v-slot="s">{{s.row.created_at | date}}</template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
  </el-card>
</template>

<script>


export default {
  name: "manage",
  data(){
    return{
      list:[],
      page:1,
      total:0,
      searchForm:{
        keyword:"",
        coupon_title:"",
        merchant_title:"",
        cashier_uid:"",
        time:[]
      }
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    searchReset(){
      this.searchForm = {
        keyword:"",
        coupon_title:"",
        merchant_title:"",
        cashier_uid:"",
        time:[]
      }
      this.searchGo();
    },
    searchGo(){
      this.page = 1;
      this.list = [];
      this.load();
    },
    pageChange(e){
      this.page = e;
      this.load();
    },
    exportExcel(){
      this.$u.api.shop.plugin.privilegeCard.cashierLogExport({
        page:this.page,
        ...this.searchForm,
      }).then(res=>{
        window.open(res)
      })
    },
    load(){
      this.$u.api.shop.plugin.privilegeCard.cashierLog({
        page:this.page,
        ...this.searchForm,
      }).then(res=>{
        this.list = res.list;
        this.total = res.total;
      })
    },
  },
}
</script>

<style scoped>

</style>